.circle {
    height: 50px;
    aspect-ratio: 1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
    z-index: 10;
    font-weight: bolder;
    font-size: 1.5rem
}

.starContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.starNumber {
    z-index: 1000;
    color: white;
    position: relative;
    font-size: 1.5rem;
    font-weight: 700;
    bottom: -2px;
}

.star {
    position: absolute;
    width: 65px;
}

.btnClic, .btn {
    background-color: transparent;
    border: none;
    color: #6A6A6B;
    font-size: 1.5rem;
    font-weight: 700;
}

.btnClic {
    cursor: pointer;
}