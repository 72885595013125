.container {
    background-color: #F9FBFD;
    border: solid 2px #F3F6F8;
    flex: 1;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    padding-top: 40px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OtomanopeeOne-Regular", sans-serif;
    font-size: 1.8vmax;
    margin: 10px;
    text-align: center;
    flex: 1
}


.text {
    margin: 10px;
    overflow-y: auto;
    font-size: 1.4vmax;
    padding: 10px 40px;
    flex: 2;
    align-items: center;
    justify-content: center;
    display: flex;
}

.qrcodeContainer {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storeContainer {
    flex: 3;
    max-height: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    background-color: #51276C;
    border-radius: 50px;
    font-size: 1.3vmax;
    color: white;
    padding: 10px 30px;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.btn:hover {
    opacity: 0.33
}