@font-face {
    font-family: 'OtomanopeeOne-Regular';
    src: local('OtomanopeeOne-Regular'), url("../../../assets/Fonts/OtomanopeeOne-Regular.ttf") format("truetype");;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OtomanopeeOne-Regular", sans-serif;
    font-size: 1.8vmax;
    margin: 10px 40px;
    text-align: center;
}