.mainContainer {
    height: 100vh;
    width: 100vw;
    background-color: #F3F6F8;
    display: flex;
}

.headerContainer {
    flex: 4;
    display: flex;
    max-width: 40%;
}

.screenContainer {
    flex: 5;
    display: flex;
    max-width: 50%;
}

.footerContainer {
    flex: 1;
    display: flex;
    max-width: 10%;
}