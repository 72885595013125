.header {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.activityInfosContainer {
    flex: 25;
    display: flex;
    max-height: 25%;
}

.progressBarContainer {
    flex: 75;
    display: flex;
    max-height: 75%;
}

.triangle {
    background-color: #f3f6f8;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    width: 100%;
    height: 100px;
    top: -1px;
    position: absolute;
}

.mascotteContainer {
    flex: 1;
    padding: 100px 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #BBED7B;
    background: linear-gradient(135deg, #BBED7B 0%, #8AC53E 100%);
    max-height: calc(100% - 130px);
}
