.header {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.activityInfosContainer {
    flex: 25;
    display: flex;
}

.progressBarContainer {
    flex: 75;
    display: flex;
    max-height: 75%
}