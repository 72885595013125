.footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
}

.timesContainer {
    flex: 1;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 2vmax;
}

.timesBtn {
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 20px;
    aspect-ratio: 1;
}

.timesBtn:hover {
    background-color: #eee;
}

.starContainer {
    flex: 6;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    font-size: 1.8vmax;
    font-weight: 700;
}

.nextContainer {
    flex: 6;
    align-items: center;
    display: flex;
    justify-content: center;
}

.timerContainer {
    flex: 5;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

.chevron {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 50px 20px;
}

.chevron:hover {
    background-color: #eee;
}

.clignote  {
    animation-duration: 1s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
}
@keyframes clignoter {
    0%   { opacity:1; }
    50%   {opacity:0; }
    100% { opacity:1; }
}

.starDiv, .starGray{
    background-size: 100%;
    background-position: top center;
    background-image: url("../../assets/Etoile.svg");
    background-repeat: no-repeat;
}

.starDiv {
    position: relative;
}

.starGray {
    position: absolute;
    top: 0;
    filter: grayscale(1);
}