body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-root {
  width: 100%;
}

/*.carousel-slider {*/
/*  height: 100%;*/
/*}*/

.carousel-slider {
  height: 100%
}

.slider-wrapper.axis-horizontal {
  height: 100%
}

.slider.animated {
  height: 100%
}
