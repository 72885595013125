.container {
    background-color: #F9FBFD;
    border: solid 2px #F3F6F8;
    flex: 1;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    position: relative;
}

.mascotteContainer {
    flex: 7;
    max-height: 62%;
    padding-bottom: 8%;
    display: flex;
    padding-left: 60px;
}

.markContainer {
    flex: 2;
    max-height: 20%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.btnContainer {
    flex: 1;
    max-height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    background-color: #51276C;
    border-radius: 50px;
    font-size: 1.5rem;
    color: white;
    padding: 10px 30px;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.btn:hover {
    opacity: 0.33
}

.smileyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 40px;
}

.smileyBtn {
    font-size: 4rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.smileyBtn:hover {
    opacity: 0.33;
}

.iconBtn {
    font-size: 3rem;
    cursor: pointer;
    margin: 20px 10px;
}

.iconBtn:hover {
    opacity: 0.33;
}

.modal {
    padding-top: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 500;
    background: url('../../assets/Modal/modal_vert.png') no-repeat center;
    background-size: contain;
    aspect-ratio: 600/635;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OtomanopeeOne-Regular", sans-serif;
    font-size: 1.7rem;
    margin: 10px;
    text-align: center;
    flex: 2
}

.subtitle {
    font-weight: bolder;
    font-size: 1.3rem;
    margin: 10px;
    text-align: center;
    flex: 1
}

.text {
    margin: 10px;
    overflow-y: auto;
    font-size: 1.1rem;
    flex: 2
}
