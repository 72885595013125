.mainContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 5px;
}


.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px;
    gap: 10px
}


@media (max-width: 960px) {
    .cardContainer {
        flex-direction: column;
    }
}

.childCard {
    flex: 1;
    display: flex;
    margin-top: -5px;
    margin-bottom: -5px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.card {
    background: #f3f6f8;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
}

@media (max-width: 1020px) {
    .defaultBtn, .secondaryBtn {
        font-size: 1vmax;
    }
}

.defaultBtn {
    background: #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2vmax;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.defaultBtn:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.defaultLink{
    color: #571088;
    font-weight: 500;
    background: none;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-size: 1vmax;
    text-decoration: none;
}

.defaultLink:hover {
    color: #4c0c78;
    text-decoration: underline;
    cursor: pointer;
}