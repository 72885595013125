.childCard {
    flex: 1;
    display: flex;
    margin-top: -5px;
    margin-bottom: -5px;
}

.card {
    background: #f3f6f8;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
}

.defaultBtn {
    background: #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.defaultBtn:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

@media (max-width: 1020px) {
    .defaultBtn {
        font-size: 1rem;
    }
}

.badge {
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
}
