.container {
    background-color: #F9FBFD;
    border: solid 2px #F3F6F8;
    flex: 1;
    margin-top: 30px;
    display: flex;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    overflow: hidden;
}

.modal {
    padding-top: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 500;
    background: url('../../assets/Modal/modal_rouge.png') no-repeat center;
    background-size: contain;
    aspect-ratio: 600/635;
}

@font-face {
    font-family: 'OtomanopeeOne-Regular';
    src: local('OtomanopeeOne-Regular'), url("../../assets/Fonts/OtomanopeeOne-Regular.ttf") format("truetype");;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OtomanopeeOne-Regular", sans-serif;
    font-size: 1.5vmax;
    margin: 10px;
    text-align: center;
}

.text {
    margin: 20px 10px;
    overflow-y: auto;
    font-size: 1.1vmax;
}

.closeBtn {
    background-color: #CE3534;
    color: white;
    border: none;
    font-size: 1vmax;
    border-radius: 80px;
    padding: 10px 20px;
    flex: 1;
    cursor: pointer;
}

.closeBtn:hover {
    opacity: 0.33
}

.leaveBtn {
    background-color: white;
    color: #CE3534;
    border: #CE3534 solid 1px;
    font-size: 1vmax;
    border-radius: 80px;
    padding: 10px 20px;
    flex: 1;
    cursor: pointer;
}

.leaveBtn:hover {
    opacity: 0.33
}

