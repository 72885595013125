.answerText {
    z-index: 1000;
    color: white;
    position: relative;
    text-align: center;
    font-size: 1.3vmax;
    font-weight: bolder;
}

.answerTextContainer {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.leftAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.leftAnswer:hover {
    opacity: 0.33;
    cursor: pointer;
}

.lockedLeftAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.selectedLeftAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.rightAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.rightAnswer:hover {
    opacity: 0.33;
    cursor: pointer;
}

.lockedRightAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.selectedRightAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.answerLine {
    flex-direction: row;
    flex: 1;
    position: relative
}

.leftImg {
    height: 100%;
    position: absolute;
    left: 0
}

.rightImg {
    height: 100%;
    position: absolute;
    right: 0
}

.icon {
    position: absolute;
    top: 10px;
    font-size: 1.8vmax;
    color: white
}

@font-face {
    font-family: 'OtomanopeeOne-Regular';
    src: local('OtomanopeeOne-Regular'), url("../../../assets/Fonts/OtomanopeeOne-Regular.ttf") format("truetype");;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OtomanopeeOne-Regular", sans-serif;
    font-size: 1.5vmax;
    margin: 10px;
    text-align: center;
}

.subtitle {
    font-weight: bolder;
    font-size: 1.1vmax;
    margin: 10px;
    text-align: center;
}

.text {
    margin: 10px;
    overflow-y: auto;
    font-size: 1.1vmax;
}

.iconBtn {
    font-size: 2.8vmax;
    cursor: pointer;
    margin: 20px 10px;
}

.iconBtn:hover {
    opacity: 0.33;
}

.goodModal {
    padding-top: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 500;
    background: url('../../../assets/Modal/modal_vert.png') no-repeat center;
    background-size: contain;
    aspect-ratio: 600/635;
}

.badModal {
    padding-top: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 500;
    background: url('../../../assets/Modal/modal_rouge.png') no-repeat center;
    background-size: contain;
    aspect-ratio: 600/635;
}