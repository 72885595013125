.line {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.materialContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.iconBtn {
    height: 80%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    border: none;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
}

.iconBtn:hover {
    opacity: 0.33;
}

.quantity {
    border-radius: 20px;
    height: 35px;
    width: 35px;
    background-color: rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    border: #C4C4C4 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vmax;
}

.check {
    border-radius: 20px;
    height: 35px;
    width: 35px;
    background-color: rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 0;
    right: 0;
    border: #C4C4C4 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vmax;
    color: #3ECA27;
}


.name {
    font-size: 1.2vmax;
}

.iconBtn {
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    max-width: 33%;
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.iconBtn:hover{
    opacity: 0.33;
}