::-webkit-scrollbar {
    display: none;
}

.triangle{
    width: 0;
    height: 0;
    border-bottom: 50px solid #F9FBFD;
    /*border-left: 100px solid transparent;*/
}

.progressBarContainer {
    background-color: #F9FBFD;
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}
