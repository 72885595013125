.dialogueContainer {
    flex: 1;
    margin: 0 5%;
    align-items: flex-end;
    display: flex;
    font-size: 1.5vmax;
    max-height: 20%;
}

.mascotteContainer {
    margin-top: 5px;
    flex: 4;
    max-height: 80%;
}