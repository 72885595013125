.container {
    background-color: #F9FBFD;
    border: solid 2px #F3F6F8;
    flex: 1;
    margin-top: 30px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    overflow: hidden;
}

.answerText {
    z-index: 1000;
    color: white;
    position: relative;
    text-align: center;
    font-size: 1.3vmax;
    font-weight: bolder;
}

.answerTextContainer {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.leftAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.leftAnswer:hover {
    opacity: 0.33;
    cursor: pointer;
}

.selectedLeftAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.rightAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.rightAnswer:hover {
    opacity: 0.33;
    cursor: pointer;
}

.selectedRightAnswer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.answerLine {
    flex-direction: row;
    position: relative
}

.leftImg {
    height: 100%;
    position: absolute;
    left: 0
}

.rightImg {
    height: 100%;
    position: absolute;
    right: 0
}

.icon {
    position: absolute;
    top: 10px;
    font-size: 2vmax;
    color: white
}