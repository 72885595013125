.container {
    background-color: #F9FBFD;
    border: solid 2px #F3F6F8;
    flex: 1;
    margin-top: 30px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    display: flex;
    overflow: hidden;
}

@font-face {
    font-family: 'OtomanopeeOne-Regular';
    src: local('OtomanopeeOne-Regular'), url("../../assets/Fonts/OtomanopeeOne-Regular.ttf") format("truetype");;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OtomanopeeOne-Regular", sans-serif;
    font-size: 1.8vmax;
    margin-top: 40px;
}

.infosContainer {
    display: flex;
    gap: 5px;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.chevron {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 50px 20px;
}

.chevron:hover {
    background-color: #eee;
}