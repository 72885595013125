.empty{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 40px;
}

.div_100 {
    min-height: 100%;
}
